<script setup>
import { ref } from 'vue'
import VueModal from './vue-modal.vue'

const props = defineProps({ title: { type: String, required: true } })

const open = ref(false)

const closeModal = () => { open.value = false }
</script>

<template>
  <a href="#" class="modal-trigger" @click.stop.prevent="open = true">{{ title }}</a>

  <Teleport to="body">
    <div
      v-if="open"
      v-click-outside="closeModal"
      class="modal dashboard-modal modal--full-width display-block corners cta--secondary"
      style="z-index: 1005; display: block; opacity: 1; top: 10%; transform: scaleX(1) scaleY(1)"
    >
      <VueModal />
      <a
        href="#!"
        class="modal-close waves-effect top-right-close modal-dismiss"
        @click="closeModal"
      >
        <i class="material-icons" style="font-size: 18px">close</i>
      </a>
    </div>
  </Teleport>
</template>
