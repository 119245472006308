<!--
Here we are reactively binding element attributes / properties to the state.
The :title syntax is short for v-bind:title.
-->

<script setup>
import { ref, computed, defineModel } from 'vue'

const passwordField = ref('')
const passwordVisible = ref(false)

const passwordInputType = computed(() => (passwordVisible.value ? 'text' : 'password'))
const revealPasswordIcon = computed(() => (passwordVisible.value ? 'visibility_off' : 'visibility'))
const revealPasswordText = computed(() => (passwordVisible.value ? 'Hide' : 'Reveal'))
</script>

<template>
  <div class="field">
    <label for="user_password" class="active">Password</label>
    <button
      id="toggle-password"
      class="right password-toggler"
      type="button"
      aria-label="Show password as plain text. Warning: this will display your password on the screen."
      @click="passwordVisible = !passwordVisible"
    >
      <span class="material-icons">{{ revealPasswordIcon }}</span> {{ revealPasswordText }}
    </button>
    <input
      id="user_password"
      v-model="passwordField"
      name="user[password]"
      placeholder="shhh..."
      autocomplete="current-password"
      class="validate"
      required="required"
      :type="passwordInputType"
    >
    <span class="character-counter">{{ passwordField.length }}</span>
  </div>
</template>
