<script setup>
import { defineProps, toRefs, onMounted } from 'vue'

  const props = defineProps(
    { 
      progress: { type: String, required: true }, 
      groupProgress: { type: String, required: true } 
    }
  )
  const { progress, groupProgress } = toRefs(props);

  function setGradient() {
    document.documentElement.style.setProperty('--gradient', "linear-gradient(to right, #2f2c7d 0% " + progress.value + "%, #48bba5 " + progress.value + "% " + groupProgress.value+ '%, white ' + groupProgress.value + '% 100%)');
  }
  onMounted(setGradient())

</script>

<template>
  <progress
    id="meter-group-percentage-matching"
    value="groupProgress.value"
    max="100"
    class="span-color display-block split-bar"
  />
</template>

<style scoped>
.split-bar {
  &::-moz-progress-bar {
      background: var(--gradient);
  }
  &::-webkit-progress-value {
    background: var(--gradient);
  }
}
</style>
