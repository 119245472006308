<script setup>
import { ref } from 'vue'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js'
import { chartMixins } from './chart_mixins';


ChartJS.register(CategoryScale, LinearScale, Title, Tooltip, Legend, BarElement)

const props = defineProps({ 
                        labels: { type: Array, required: false, default: () => []}, 
                        data: { type: Array, required: true }, 
                        chartId : { type: String, required: true },
                        backgroundColor: { type: Array, required: false, default: () => ['#00a0dc', '#48bba5', '#2f2c7d', '#e63e40']}
                      })


const chartData = ref({
        labels: props.labels,
        datasets: [ { data: props.data, backgroundColor: props.backgroundColor, offset: 5 } ]
      })
const chartOptions = ref({
  responsiveness: true,
  plugins: {
      legend: {
        display: false, // Use this boolean to toggle legend display
      },
      tooltip: {
        callbacks: {
          label: function({raw}) {
            return chartMixins.methods.formatLabelAsPercent(raw)
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false // Hide vertical grid lines
        }
      },
      y: {
        border: {
          display: false // Hide first vertical line
        },
        ticks: {
            // Include percent sign after vertical axis ticks
            callback: function(value, _index, _ticks) {
              return chartMixins.methods.formatLabelAsPercent(value)
            }
        }
      }
    }
  })
</script>

<template>
  <Bar
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
  />
</template>
