<script setup>
import { ref } from 'vue'
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js'
import { chartMixins } from './chart_mixins';

ChartJS.register(Title, Tooltip, Legend, ArcElement)

const props = defineProps({ 
                    labels: { type: Array, required: false, default: () => []}, 
                    data: { type: Array, required: true }, 
                    chartId: { type: String, required: true },
                    backgroundColor: { type: Array, required: false, default: () => ['#2f2c7d', '#00a0dc']}
                  })

const chartData = ref({
        labels: props.labels,
        datasets: [ { data: props.data, backgroundColor: props.backgroundColor, offset: 5 } ]
      })
const chartOptions = ref({
  responsiveness: true,
  plugins: {
      legend: {
        display: false, // Use this boolean to toggle legend display
      },
      tooltip: {
        callbacks: {
          label: function({raw}) {
            return chartMixins.methods.formatLabelAsPercent(raw)
          }
        }
      }
    }
  })
</script>

<template>
  <div id="piechart" class="center-align flex-center">
    <section class="position-relative">
      <figure>
        <Pie
          :id="chartId"
          :options="chartOptions"
          :data="chartData"
          class="margin-20-bottom stat-box-piechart"
        />
      </figure>
    </section>
  </div>
</template>
